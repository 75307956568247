<template>
    <div>
        <van-sticky>
            <title-bar2 title="查资料" />
            <title-search :value.sync="keyword" placeholder="请输入搜索资料" @search="onSearch" />
        </van-sticky>
        <div class="document-divider"></div>
        <div class="guide-box">
            <span class="num-box">
                <span class="num1">1</span>
                <span class="num2">/</span>
                <span class="num2">2</span>
            </span>
            <span class="txt">请输入查询信息</span>
        </div>
        <div class="document-divider"></div>
        <div class="card-box">
            <document-filter :filterActive.sync="filterActive" :filterData1.sync="filterData1" :filterData2.sync="filterData2" :filterData3.sync="filterData3" @filter="onFilter"></document-filter>
        </div>
        <div class="document-divider"></div>
        <div class="guide-box">
            <span class="num-box">
                <span class="num1">2</span>
                <span class="num2">/</span>
                <span class="num2">2</span>
            </span>
            <span class="txt">查询结果（点击可预览）</span>
        </div>
        <van-tabs v-model="typeActive" @change="onChange">
            <van-tab :title="`全部 ${countAll}`"></van-tab>
            <van-tab :title="`真题 ${countExamSubject}`"></van-tab>
            <van-tab :title="`初试 ${countFirstSubject}`"></van-tab>
            <van-tab :title="`复试 ${countSecondSubject}`"></van-tab>
        </van-tabs>
        <van-list class="document-list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
            <template v-for="documentInfo in documentInfos">
                <document-item class="document-item" :documentName="documentInfo.DocumentName" :documentPrice="documentInfo.DocumentPrice" @item-click="onItemClick(documentInfo)"></document-item>
            </template>
        </van-list>
    </div>
</template>

<script>
import TitleBar2 from "@/components/MT/TitleBar2";
import TitleSearch from "@/components/MT/TitleSearch";
import DocumentFilter from "@/components/MT/DocumentFilter";
import DocumentItem from "@/components/MT/DocumentItem";
import Config from "@/api/service";

export default {
    name: "MTModule",
    components: {
        TitleBar2,
        TitleSearch,
        DocumentFilter,
        DocumentItem,
    },
    data() {
        return {
            keyword: null,
            index: 0,
            total: 0,
            loading: false,
            finished: false,
            countAll: 0,
            countFirstSubject: 0,
            countSecondSubject: 0,
            keyword: '',
            typeActive: 0,
            filterActive: 0,
            filterData1: {
                universityCode: '',
                collegeCode: '',
                majorCode: '',
            },
            filterData2: {
                universityCode: '',
                examSubjectCode: '',
            },
            filterData3: {
                universityCode: '',
                majorCode: '',
                examSubjectName: '',
            },
            documentInfos: [],
        };
    },
    methods: {
        //获取路由参数
        getRoutParams() {
            this.keyword = this.$route.query.keyword;
        },
        //获取文档数量
        getDocumentCount() {
            if (this.filterActive == 0) {
                var model = {
                    SearchKeyword: this.keyword,
                    UniversityCode: this.filterData1.universityCode,
                    MajorCode: this.filterData1.majorCode,
                };
            } else if (this.filterActive == 1) {
                var model = {
                    SearchKeyword: this.keyword,
                    UniversityCode: this.filterData2.universityCode,
                    ExamSubjectCode: this.filterData2.examSubjectCode,
                };
            } else if (this.filterActive == 2) {
                var model = {
                    SearchKeyword: this.keyword,
                    UniversityCode: this.filterData3.universityCode,
                    MajorCode: this.filterData3.majorCode,
                };
            }
            this.$axios
                .post(Config.document.count, model)
                .then((res) => {
                    let data = res.data.Data;
                    this.countAll = data.CountAll;
                    this.countFirstSubject = data.CountFirstSubject;
                    this.countSecondSubject = data.CountSecondSubject;
                    this.countExamSubject = data.CountExamSubject;
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //获取文档列表
        getDocumentInfos() {
            this.loading = true;
            if (this.filterActive == 0) {
                var model = {
                    SearchKeyword: this.keyword,
                    UniversityCode: this.filterData1.universityCode,
                    MajorCode: this.filterData1.majorCode,
                    PageIndex: ++this.index,
                    PageSize: 20,
                };
            } else if (this.filterActive == 1) {
                var model = {
                    SearchKeyword: this.keyword,
                    UniversityCode: this.filterData2.universityCode,
                    ExamSubjectCode: this.filterData2.examSubjectCode,
                    PageIndex: ++this.index,
                    PageSize: 20,
                };
            } else if (this.filterActive == 2) {
                var model = {
                    SearchKeyword: this.keyword,
                    UniversityCode: this.filterData3.universityCode,
                    MajorCode: this.filterData3.majorCode,
                    PageIndex: ++this.index,
                    PageSize: 20,
                };
            }
            if (this.typeActive == 1) {
                model.DocumentType = 2;
            } else if (this.typeActive == 2) {
                model.DocumentType = 0;
            } else if (this.typeActive == 3) {
                model.DocumentType = 1;
            }
            this.$axios
                .post(Config.document.list, model)
                .then((res) => {
                    let data = res.data.Data;
                    this.documentInfos.push(...data.DocumentInfos);
                    this.loading = false;
                    this.total = data.TotalCount;
                    if (this.index >= data.PageCount) {
                        this.finished = true;
                    }
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                }).finally(() => {
                    this.loading = false;
                });
        },
        //点击资料项
        onItemClick(documentInfo) {
            this.$router.push({
                name: 'Document1',
                params: {
                    proxyCode: this.$store.state.proxyCode,
                    documentGuid: documentInfo.DocumentGuid
                }
            })
        },
        //点击搜索
        onSearch() {
            for (var key in this.filterData1) {
                this.filterData1[key] = null;
            }
            for (var key in this.filterData2) {
                this.filterData2[key] = null;
            }
            for (var key in this.filterData3) {
                this.filterData3[key] = null;
            } debugger
            this.onReload();
        },
        //点击过滤
        onFilter() {
            this.keyword = '';
            this.onReload();
        },
        //重新加载列表
        onReload() {
            this.finished = false;
            this.loading = true;
            this.index = 0;
            this.documentInfos = [];
            this.getDocumentCount();
            this.getDocumentInfos();
        },
        //加载列表
        onLoad() {
            this.getDocumentInfos();
        },
        //选项卡切换
        onChange() {
            this.onReload();
        },
    },
    mounted() {
        this.getRoutParams();
        this.getDocumentCount();
        this.getDocumentInfos();
    }
};
</script>

<style scoped lang="less">
.document-list {
    margin-top: 10px;
}

.document-item {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.document-divider {
    margin: 20px 0;
}

.guide-box {
    background-color: #F6F6F6;
    padding-left: 10px;
    border-width: 0px;
    font-size: 14px;
    font-weight: 600;

    .num-box {
        display: inline;
        letter-spacing: 5px;

        .num1 {
            color: #FF6602;
            font-size: 18px;
            font-weight: 700;
        }

        .num2 {
            color: gray;
            font-size: 14px;
        }
    }
}

.card-box {
    background-color: white;
    margin: 10px;
    padding: 5px;
    border-radius: 10px;
}

::v-deep .van-tabs__nav {
    background-color: transparent;
}

::v-deep .el-table__fixed {
    z-index: 0;
}

::v-deep .el-table td.el-table__cell div {
    white-space: pre-wrap;
}
</style>